import { Box, Chip, Grid2, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getFacilityReport } from '../../../../helpers/api';
import LoaderSkeleton from '../../../common/LoaderSkeleton';
import { Empty } from 'antd';
import StandardResultMeasurementInputs from './StandardResultMeasurementInputs';

export default function StandardResultMeasurement({ report }) {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [selectedSamplePoint, setSelectedSamplePoint] = useState();

  const fetchData = () => {
    setLoading(true);
    getFacilityReport(report?.facilityId, report?.reportQuarter, report?.reportYear)
      .then((response) => {
        const responseData = response?.result?.data;
        const initialSamplePoint = responseData?.samplePoints[0];
        setData(responseData);
        setSelectedSamplePoint(initialSamplePoint);
      })
      .catch((error) => {})
      .finally(() => setLoading(false));
  };

  const handleRendering = () => {
    if (loading) {
      return (
        <Box sx={{ padding: '2rem' }}>
          <LoaderSkeleton />
        </Box>
      );
    }

    if (!loading && !data) {
      return (
        <Box>
          <Empty />
        </Box>
      );
    }

    if (!loading && data) {
      return renderContent();
    }
  };

  const renderContent = () => {
    return (
      <Grid2
        spacing={1}
        container
        sx={{
          padding: '2rem',
          //height: '300px',
          //overflowY: 'scroll'
        }}
      >
        {data?.samplePoints?.map((item, index) => (
          <Grid2
            size={{ lg: 2, md: 2, sm: 3, xs: 6 }}
            key={index}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              padding: '2rem',
              borderRadius: '10px',
              background:
                item?.samplePointId === selectedSamplePoint?.samplePointId ? '#699ad0' : '#d9d9d9',
              '&:hover': {
                background: '#f5f7ff',
                cursor: 'pointer',
                opacity: 0.8,
              },
            }}
            onClick={() => {
              setSelectedSamplePoint(item);
            }}
          >
            <Box
              sx={{
                width: '50px',
                height: '50px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {!item?.icon ? (
                ''
              ) : (
                <img
                  src={item?.icon}
                  alt="icon"
                  style={{ objectFit: 'contain', width: '50px', height: '50px' }}
                />
              )}
            </Box>
            <Box>
              <Typography variant="body2" sx={{ fontWeight: 'bold', textTransform: 'capitalize' }}>
                {item?.samplePointName}
              </Typography>
            </Box>
            <Typography variant="caption" sx={{ fontWeight: 'light', textTransform: 'capitalize' }}>
              {item?.measurementName}
            </Typography>
          </Grid2>
        ))}
      </Grid2>
    );
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <Box>
      <Box sx={{ display: 'flex', gap: 5, padding: '0 2rem' }}>
        <Box>
          <Typography color="textPrimary" variant="body2">
            Title
          </Typography>
          <Typography color="primary" sx={{ fontWeight: 'bold' }}>
            {report?.reportTitle}
          </Typography>
        </Box>
        <Box>
          <Typography color="textPrimary" variant="body2">
            Facility
          </Typography>
          <Typography color="primary" sx={{ fontWeight: 'bold' }}>
            {data?.facility}
          </Typography>
        </Box>
        <Box>
          <Typography color="textPrimary" variant="body2">
            Create Date
          </Typography>
          <Typography color="primary" sx={{ fontWeight: 'bold' }}>
            {new Intl.DateTimeFormat('en-NG', {
              year: 'numeric',
              month: 'short',
              day: '2-digit',
            }).format(report?.entryDate ? new Date(report.entryDate) : new Date())}
          </Typography>
        </Box>
        <Box>
          <Chip
            label={report?.reportType}
            color={report?.reportType === 'QAQC' ? 'warning' : 'success'}
          />
        </Box>
      </Box>
      <Box>{handleRendering()}</Box>
      <Box sx={{ padding: '1rem 2rem' }}>
        <StandardResultMeasurementInputs
          report={report}
          samplePoint={selectedSamplePoint}
          onSelectReport={(value, item) => {
            console.log(item);
          }}
        />
      </Box>
    </Box>
  );
}
