import React from 'react';
import PageContainer from '../components/common/PageContainer';
import Layout from '../components/common/Layout';
import ReportingScreen from '../components/screens/reporting/ReportingScreen';

export default function Reporting(props) {
  return (
    <Layout headerTitle={'Reporting'}>
      <PageContainer id="reporting">
        <ReportingScreen props={props} id="reporting" />
        {/* dfsdfsdfsfsd */}
      </PageContainer>
    </Layout>
  );
}
